import { VictoryChart, VictoryTheme, VictoryLine, VictoryLabel, VictoryScatter, VictoryAxis } from 'victory'

type Props = {
  tScore: {
    L: number
    F: number
    K: number
    1: number
    2: number
    3: number
    4: number
    5: number
    6: number
    7: number
    8: number
    9: number
    0: number
  }
}

function Chart({ tScore }: Props) {
  return (
    <div style={{ maxWidth: 800 }}>
      <VictoryChart theme={VictoryTheme.material} domain={{ y: [0, 130] }} height={600} width={800}>
        <VictoryAxis label="Шкалы" axisLabelComponent={<VictoryLabel dy={25} />} />
        <VictoryAxis
          dependentAxis
          label="T-баллы"
          tickValues={[0, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120]}
          axisLabelComponent={<VictoryLabel dy={-30} />}
        />
        <VictoryScatter
          data={[
            { x: ' ', y: null },
            { x: 'L', y: tScore.L },
            { x: 'F', y: tScore.F },
            { x: 'K', y: tScore.K },
            { x: '1', y: tScore['1'] },
            { x: '2', y: tScore['2'] },
            { x: '3', y: tScore['3'] },
            { x: '4', y: tScore['4'] },
            { x: '5', y: tScore['5'] },
            { x: '6', y: tScore['6'] },
            { x: '7', y: tScore['7'] },
            { x: '8', y: tScore['8'] },
            { x: '9', y: tScore['9'] },
            { x: '0', y: tScore['0'] },
            { x: '  ', y: null },
          ]}
          size={3}
        />
        <VictoryLine
          data={[
            { x: 'L', y: tScore.L },
            { x: 'F', y: tScore.F },
            { x: 'K', y: tScore.K },
            { x: 'K', y: null },
            { x: '1', y: tScore['1'] },
            { x: '2', y: tScore['2'] },
            { x: '3', y: tScore['3'] },
            { x: '4', y: tScore['4'] },
            { x: '5', y: tScore['5'] },
            { x: '6', y: tScore['6'] },
            { x: '7', y: tScore['7'] },
            { x: '8', y: tScore['8'] },
            { x: '9', y: tScore['9'] },
            { x: '0', y: tScore['0'] },
          ]}
          labels={({ datum }) => datum.y}
          labelComponent={
            <VictoryLabel backgroundStyle={{ fill: '#fff' }} backgroundPadding={2} dy={-12}></VictoryLabel>
          }
        />
      </VictoryChart>
    </div>
  )
}

export default Chart
