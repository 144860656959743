import { QuestionType } from './types'
import Paper from '@mui/material/Paper'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

type Props = {
  question: QuestionType
  check: number | null
  onChange: (check: number) => void
}

function Question({ question, check, onChange }: Props) {
  return (
    <Paper elevation={1} sx={{ padding: 1, margin: 1 }}>
      <div style={{ minHeight: 100 }}>
        <span style={{ display: 'inline-block' }}>{question.question_id}. {question.text}</span>
      </div>
      <div>
        <ToggleButtonGroup
          color="primary"
          sx={{ margin: 1 }}
          value={check}
          exclusive
          onChange={(e, check) => check !== null && onChange(check)}
        >
          <ToggleButton value={1}>Верно</ToggleButton>
          <ToggleButton value={0}>Неверно</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </Paper>
  )
}

export default Question
