import { useEffect, useState } from 'react'
import Blank from './Blank'
import axios from 'axios'
import { BlankType, DecodedResultType } from './types'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import TextField from '@mui/material/TextField'

const urlPrefix = window.location.host === 'victoriya-reshetnikova.com' ? 'https://mmpi-mmil.resivalex.com' : ''

function App() {
  const [questions, setQuestions] = useState([])
  const [decoded, setDecoded] = useState<DecodedResultType[]>([])
  const [isTestStarted, setIsTestStarted] = useState(false)
  const [passedKey, setPassedKey] = useState('')

  function parseResponse(data: any): DecodedResultType {
    return {
      key: data['key'],
      timestamp: new Date(data['timestamp'] * 1000),
      sex: data['sex'],
      rawScore: data['raw_score'],
      tScore: data['t_score'],
    }
  }

  function processBlank(blank: BlankType) {
    async function requestDecoding() {
      const response = await axios.post(urlPrefix + '/decode', blank)
      const responseData: any = response.data
      setDecoded([parseResponse(responseData)])
    }
    requestDecoding()
  }

  function loadExample() {
    async function requestExample() {
      const response = await axios.get(urlPrefix + '/example')
      const responseData: any = response.data
      setDecoded([parseResponse(responseData)])
    }
    requestExample()
  }

  function loadPassed(passedKey: string) {
    async function requestPassed() {
      const response = await axios.get(urlPrefix + '/load', { params: { key: passedKey } })
      const responseData: any = response.data
      if (responseData.error === 'not_found') {
        return
      }
      setDecoded([parseResponse(responseData)])
    }
    requestPassed()
  }

  useEffect(() => {
    async function loadQuestions() {
      const questions = await axios.get(urlPrefix + '/questions')
      setQuestions(questions.data)
    }
    loadQuestions()

    const query = new URLSearchParams(window.location.search);
    const key = query.get('key')

    if (key) {
      loadPassed(key)
    }
  }, [])

  return (
    <div className="notranslate" translate="no">
      {isTestStarted || decoded.length === 1 ? (
        <Blank
          questions={questions}
          onComplete={(blank) => processBlank(blank)}
          decodedResult={decoded[0] || null}
        ></Blank>
      ) : (
        <>
          <div>
            <ButtonGroup>
              <Button onClick={() => setIsTestStarted(true)}>Пройти тест</Button>
            </ButtonGroup>
          </div>
          <div style={{ height: 20 }}></div>
          <div>
            <ButtonGroup>
              <Button onClick={() => loadExample()}>Посмотреть пример результатов</Button>
            </ButtonGroup>
          </div>
          <div style={{ height: 20 }}></div>
          <div>
            <TextField
              label="Код теста"
              size="small"
              value={passedKey}
              onChange={(e) => setPassedKey(e.target.value)}
            />
            <ButtonGroup>
              <Button style={{ height: 40 }} onClick={() => loadPassed(passedKey)}>
                Получить результаты
              </Button>
            </ButtonGroup>
          </div>
        </>
      )}
    </div>
  )
}

export default App
