import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Tooltip from '@mui/material/Tooltip'

type Props = {
  activeStep: number
  stepCount: number
  isPageCorrect: boolean
  onStepChange: (step: number) => void
  onComplete: () => void
}
function BlankStepper({ activeStep, stepCount, isPageCorrect, onStepChange, onComplete }: Props) {
  const theme = useTheme()

  const handleNext = () => {
    if (!isPageCorrect) {
      return
    }
    if (activeStep === stepCount - 1) {
      onComplete()
    } else {
      onStepChange(activeStep + 1)
    }
  }

  const handleBack = () => {
    onStepChange(activeStep - 1)
  }

  return (
    <MobileStepper
      variant="progress"
      steps={stepCount}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Tooltip title={isPageCorrect ? '' : 'Ответ не выбран'}>
          <span>
            <Button size="small" onClick={handleNext} disabled={!isPageCorrect}>
              {activeStep === stepCount - 1 ? 'Результаты' : 'Далее'}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          </span>
        </Tooltip>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Назад
        </Button>
      }
    />
  )
}

export default BlankStepper
