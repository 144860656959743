import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import Chart from './Chart'
import { DecodedResultType } from './types'

type Props = {
  decodedResult: DecodedResultType
}

function Result({ decodedResult }: Props) {
  const { key, sex, timestamp, rawScore, tScore } = decodedResult
  const tableContent = [
    ['L', tScore.L, rawScore.L],
    ['F', tScore.F, rawScore.F],
    ['K', tScore.K, rawScore.K],
    ['1', tScore['1'], ''],
    ['2', tScore['2'], ''],
    ['3', tScore['3'], ''],
    ['4', tScore['4'], ''],
    ['5', tScore['5'], ''],
    ['6', tScore['6'], ''],
    ['7', tScore['7'], ''],
    ['8', tScore['8'], ''],
    ['9', tScore['9'], ''],
    ['0', tScore['0'], ''],
  ]
  const resultLink = window.location.protocol + '//' + window.location.host + window.location.pathname + '?key=' + key
  return (
    <>
      <h2>Результаты теста</h2>
      <div>
        Доступ к результатам: по коду <strong>{key}</strong> или по ссылке <Link href={resultLink}>{resultLink}</Link>
        <br />
        <br />
        Пройден: {timestamp.toISOString().replace('T', ' ').slice(0, 16)}
        <br />
        Пол: {sex === 'male' ? 'Мужской' : 'Женский'}
        <br />
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}>
        <div>
          <Chart tScore={tScore}></Chart>
        </div>
        <div>
          <TableContainer component={Paper} style={{ width: 'min-content', whiteSpace: 'nowrap' }}>
            <Table size="small">
              <TableBody>
                <TableRow style={{ verticalAlign: 'top' }}>
                  <TableCell align="left">Шкала</TableCell>
                  <TableCell align="left">T-баллы</TableCell>
                  <TableCell align="left">
                    Сырые
                    <br />
                    баллы
                  </TableCell>
                </TableRow>
                {tableContent.map((rowData, rowIndex) => {
                  return (
                    <TableRow key={rowIndex}>
                      {rowData.map((item, colIndex) => {
                        return <TableCell key={colIndex}>{item}</TableCell>
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default Result
