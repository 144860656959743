import { useEffect, useState } from 'react'
import { BlankType, QuestionType, DecodedResultType, IncompleteAnswersType } from './types'
import Question from './Question'
import Paper from '@mui/material/Paper'
import Result from './Result'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import BlankStepper from './BlankStepper'

type Props = {
  questions: QuestionType[]
  onComplete: (blank: BlankType) => void
  decodedResult: DecodedResultType | null
}

function Blank({ questions, onComplete, decodedResult }: Props) {
  const [sex, setSex] = useState<string | null>(null)
  const [answers, setAnswers] = useState<IncompleteAnswersType>({})
  const [activeStep, setActiveStep] = useState<number>(0)

  useEffect(() => {
    const answers: IncompleteAnswersType = {}
    questions.forEach((question) => {
      answers[question.question_id] = null
    })
    setAnswers(answers)
  }, [questions])

  function setAnswer(question_id: string, check: number) {
    const firstCheck = answers[question_id] === null
    setAnswers({ ...answers, [question_id]: check })
    if (firstCheck && activeStep !== totalPages() - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1)
      }, 400)
    }
  }

  function setSexStr(sexStr: string) {
    const firstCheck = sex === null
    setSex(sexStr)
    if (firstCheck) {
      setTimeout(() => {
        setActiveStep(activeStep + 1)
      }, 400)
    }
  }

  function complete() {
    const blank: any = {
      sex: sex,
      answers: answers,
    }
    onComplete(blank)
  }

  function totalPages() {
    return 1 + questions.length
  }

  function showPage() {
    if (activeStep === 0) {
      return (
        <Paper elevation={1} sx={{ padding: 1, margin: 1 }}>
          <div style={{ height: 100 }}>
            <div>Выберите пол</div>
          </div>
          <ToggleButtonGroup
            color="primary"
            sx={{ margin: 1 }}
            value={sex}
            exclusive
            onChange={(e, sex) => sex !== null && setSexStr(sex)}
          >
            <ToggleButton value="male">Мужской</ToggleButton>
            <ToggleButton value="female">Женский</ToggleButton>
          </ToggleButtonGroup>
        </Paper>
      )
    } else {
      const question = questions[activeStep - 1]
      return (
        <Question
          question={question}
          check={answers[question.question_id]}
          onChange={(check) => setAnswer(question.question_id, check)}
        />
      )
    }
  }

  function isCurrentPageCorrect() {
    if (activeStep === 0) {
      return sex !== null
    } else {
      const question = questions[activeStep - 1]
      if (answers[question.question_id] === null) {
        return false
      }
    }
    return true
  }

  function showStepper() {
    return (
      <BlankStepper
        activeStep={activeStep}
        isPageCorrect={isCurrentPageCorrect()}
        onStepChange={(step) => setActiveStep(step)}
        stepCount={totalPages()}
        onComplete={() => complete()}
      />
    )
  }

  function showBlank() {
    return (
      <div>
        <h2>Вопросы</h2>
        {showStepper()}
        {showPage()}
      </div>
    )
  }

  return <div>{decodedResult ? <Result decodedResult={decodedResult} /> : showBlank()}</div>
}

export default Blank
