import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import reportWebVitals from './reportWebVitals'

const rootElement = document.getElementById('mmpi-mmil-root')

const shadowContainer = rootElement!.attachShadow({ mode: 'open' })
const emotionRoot = document.createElement('style')
const shadowRootElement = document.createElement('div')
shadowContainer.appendChild(emotionRoot)
shadowContainer.appendChild(shadowRootElement)

const cache = createCache({
  key: 'css',
  prepend: true,
  container: emotionRoot,
})

ReactDOM.createRoot(shadowRootElement).render(
  <CacheProvider value={cache}>
    <App />
  </CacheProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
